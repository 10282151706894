/**
 * 產險清單篩選
 */
const InsuranceListFilterTitles = {
    manCode: '姓名/帳號',
    validDate: '有效區間(起)',
    _seq: ['manCode', 'validDate']
}

/**
 * 產險清單table columns setting
 */
const InsuranceListColumns = {
    insDetailLink: {
        label: '明細',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        widthPx: 65,
        isExcelHide: true
    },
    mainCode: {
        label: '保單流水號',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 8,
        widthPx: 120
    },
    insNo: {
        label: '保單號碼',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 8,
        widthPx: 150
    },
    supplier: {
        label: '保險公司',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 8,
        widthPx: 110
    },
    payer: {
        label: '要保人',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 8,
        widthPx: 100
    },
    insured: {
        label: '被保人',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 11,
        widthPx: 100
    },
    effectiveDate: {
        label: '生效日',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        width: 8,
        widthPx: 100
    },
    expectDueDate: {
        label: '預期到期日',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        width: 14,
        widthPx: 120,
        toolTips: {
            hasToolTips: true,
            content: {
                desktop: '保單資料的到期日，預設為生效日+1年',
                mobile: null
            }
        }
    },
    manName: {
        label: '招攬人',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 8,
        widthPx: 90
    }
}

/**
 * PDF 相關常數設置
 */
const PDF_CONFIG = {
    COLOR: {
        BLACK: [0, 0, 0],
        RED: [255, 0, 0],
        YELLOW: [255, 255, 0]
    },
    FONT_SIZE: 12,
    LAYOUT: {
        PADDING: 40, // pdf page 預設 padding 40
        ROW_MARGIN: 16, // font size + 4
        EMPTY_COLUMN_WIDTH: 90 // 留空欄位設置固定寬
    }
}

export { InsuranceListFilterTitles, InsuranceListColumns, PDF_CONFIG }
